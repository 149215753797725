import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Routes } from '../../consts/routes';
import { AppState } from '../../store/store';
import styles from './AddressSummary.module.css';

const Text = React.lazy(() => import('../../components/Text/Text'));

const AddressSummary = () => {
  const { t } = useTranslation();
  const {
    position,
    address,
    location: { details: locationDetails },
  } = useSelector((state: AppState) => state);

  if (!position || !address) {
    return <Redirect to={Routes.LocationDetails} />;
  }

  const locationDetailsInfo = `${
    locationDetails.roomNumber
      ? `Indoors - ${t('common.room')}: ${locationDetails.roomNumber} `
      : ''
  } ${
    locationDetails.floorNumber
      ? `• ${t('common.floor')}: ${locationDetails.floorNumber} `
      : ''
  } ${
    locationDetails.exitNumber
      ? `On the Move - ${t('common.exit')}: ${locationDetails.exitNumber} `
      : ''
  } ${
    locationDetails.busNumber
      ? `• ${t('common.busNumber')}: ${locationDetails.busNumber}`
      : ''
  } ${
    locationDetails.outdoorsDetails ? `Outdoors - ${locationDetails.outdoorsDetails}` : ''
  }`;

  return (
    <div>
      <div className={cn('flex', 'flex-col',styles.container)}>
        <div className={cn(styles.title)}>
          <Text weight='bold'>{t('common.location')}</Text>
        </div>
        
        {address.City && (
            <Link className={cn(styles['link'])} to={Routes.LocationDetails}>
              <div className={cn(styles['address-container'])}>
                <div className={styles['address-detail']}>
                  <Text weight='slight-bold'>{address.Address}</Text>
                  <Text weight='slight-bold'>{`${address.City}, ${address.Postal}`}</Text>
                </div>
                <div className={styles['edit-button']}>
                  <Text color='primary'>{t('common.edit')}</Text>
                </div>
              </div>
            </Link>
          )}
            
        {locationDetailsInfo.trim().length > 0 && (
          <Link className={cn(styles['link'])} to={Routes.DescribeLocation}>
            <div className={cn(styles['address-container'])}>
              <div className={styles['address-detail']}>
                <Text weight='slight-bold' size='small'>
                  {locationDetailsInfo}
                </Text>
              </div>
              <div className={styles['edit-button']}>
                <Text color='primary'>{t('common.edit')}</Text>
              </div>
            </div>
          </Link>
        )}
        </div>
      </div>
  );
};

export default AddressSummary;
