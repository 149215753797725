import './i18n';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';

import Div100vh from 'react-div-100vh';
import { LocationProvider } from './contexts/LocationContext';
import Logo from './icons/assets/Logo.webp';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
import { Refresher } from './containers';
import { Routes } from './consts/routes';
import { isMobileDevice } from './utils/detectDeviceType';
import { persistStore } from 'redux-persist';
import { store } from './store/store';
import styles from './pages/AddressPage/AddressPage.module.css';
import { useCheckNetworkConnection } from './hooks/useCheckNetworkConnection';
import { useTranslation } from 'react-i18next';

const NoInternetConnection = React.lazy(
  () => import('./pages/NoInternetConnectionPage/NoInternetConnectionPage')
);
const DeviceNotSupported = React.lazy(
  () => import('./pages/DeviceNotSupported/DeviceNotSupported')
);
const Initial = React.lazy(() => import('./pages/InitialPage/InitialPage'));
const Address = React.lazy(() => import('./pages/AddressPage/AddressPage'));
const Setup = React.lazy(() => import('./pages/SetupPage/SetupPage'));
const VerifyPhoneNumber = React.lazy(
  () => import('./pages/VerifyPhoneNumberPage/VerifyPhoneNumberPage')
);
const Name = React.lazy(() => import('./pages/NamePage/NamePage'));
const Location = React.lazy(() => import('./pages/LocationPage/LocationPage'));
const LocationDetails = React.lazy(
  () => import('./pages/LocationDetailsPage/LocationDetailsPage')
);
const Urgency = React.lazy(() => import('./pages/UrgencyPage/UrgencyPage'));
const Emergency = React.lazy(
  () => import('./pages/ReportEmergency/ReportEmergency')
);
const Accommodations = React.lazy(
  () => import('./pages/AccommodationsPage/AccommodationsPage')
);
const Describe = React.lazy(
  () => import('./pages/DescribeEmergency/DescribeEmergency')
);
const Weapons = React.lazy(
  () => import('./pages/WeaponsInvolved/WeaponsInvolved')
);
const DescribeLocation = React.lazy(
  () => import('./pages/DescribeLocation/DescribeLocation')
);
const AdditionalDetails = React.lazy(
  () => import('./pages/AdditionalInfo/AdditionalInfo')
);
const Report = React.lazy(() => import('./pages/ReportPage/ReportPage'));

const Summary = React.lazy(() => import('./pages/SummaryPage/SummaryPage'));
const TestModeReport = React.lazy(
  () => import('./pages/TestModeReport/TestModeReport')
);

const Button = React.lazy(() => import('./components/Button/Button'));
const Loader = React.lazy(() => import('./components/Loader/Loader'));
const Modal = React.lazy(() => import('./components/Modal/Modal'));
const ModalBody = React.lazy(() => import('./components/Modal/ModalBody'));
const Text = React.lazy(() => import('./components/Text/Text'));

const setupComplete = localStorage.getItem('setup-complete') === 'true';
const dev =
  process.env.REACT_APP_TEST_ENV === undefined
    ? false
    : process.env.REACT_APP_TEST_ENV === 'true';
const isMobile = isMobileDevice();

const persistor = persistStore(store);

if (process.env.REACT_APP_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
}

function App() {
  const { isOnline } = useCheckNetworkConnection();

  const { ready, t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);

  const mainComponent = () => {
    if (!isMobile && !dev) {
      return DeviceNotSupported;
    }

    if (setupComplete) {
      return LocationDetails;
    }

    return Initial;
  };

  useEffect(() => {
    const modalShowed = localStorage.getItem('modalShowed') === 'true';
    if (!modalShowed) {
      setShowModal(true);
      localStorage.setItem('modalShowed', true.toString());
    }
  }, []);

  return (
    <Suspense fallback=''>
      <Div100vh style={{ overflow: 'auto' }}>
        <Modal
          withoutXButton
          placementCenter
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          {ready ? (
            <ModalBody>
              <div className={styles.alertModal}>
                <img src={Logo} alt='accesSos' width='96px' height='121px' />
                <Text size='big'>{t('availabilityPopup.header')}</Text>
                <Text size='medium' className={styles.alertDescription}>
                  {t('availabilityPopup.subheader')}
                </Text>
                <Text size='medium' className={styles.alertDescription}>
                  {t('availabilityPopup.tos')}
                  <a
                    href='https://www.notion.so/accessos/Terms-of-Use-Privacy-Policy-f56ed8fb819d4a22b20dc08c0b1358d7'
                    rel='noreferrer'
                    target='_blank'
                  >
                    <Text color='blue'>{t('availabilityPopup.link-tos')}</Text>
                  </a>
                </Text>

                <Button onClick={() => setShowModal(false)}>Continue</Button>
              </div>
            </ModalBody>
          ) : (
            <div className='loading-center'>
              <div className='lds-ring'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </Modal>
        <Provider store={store}>
          {isOnline ? (
            <Suspense fallback={<Loader />}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                  <Refresher>
                    <LocationProvider>
                      {!dev && !isMobile ? (
                        <Switch>
                          <Route
                            exact
                            component={DeviceNotSupported}
                            path={Routes.Main}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.LocationDetails}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Location}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Setup}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Address}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Summary}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Urgency}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Emergency}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Accommodations}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Weapons}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Describe}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.DescribeLocation}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.AdditionalDetails}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Report}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.TestModeReport}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Name}
                          />
                          <Route
                            component={DeviceNotSupported}
                            path={Routes.Verify}
                          />
                        </Switch>
                      ) : (
                        <Switch>
                          <Route
                            exact
                            component={mainComponent()}
                            path={Routes.Main}
                          />
                          <Route
                            component={LocationDetails}
                            path={Routes.LocationDetails}
                          />
                          <Route component={Location} path={Routes.Location} />
                          <Route component={Setup} path={Routes.Setup} />
                          <Route component={Address} path={Routes.Address} />
                          <Route component={Summary} path={Routes.Summary} />
                          <Route component={Urgency} path={Routes.Urgency} />
                          <Route
                            component={Emergency}
                            path={Routes.Emergency}
                          />
                          <Route
                            component={Accommodations}
                            path={Routes.Accommodations}
                          />
                          <Route component={Weapons} path={Routes.Weapons} />
                          <Route component={Describe} path={Routes.Describe} />
                          <Route
                            component={DescribeLocation}
                            path={Routes.DescribeLocation}
                          />
                          <Route
                            component={AdditionalDetails}
                            path={Routes.AdditionalDetails}
                          />
                          <Route component={Report} path={Routes.Report} />
                          <Route
                            component={TestModeReport}
                            path={Routes.TestModeReport}
                          />
                          <Route component={Name} path={Routes.Name} />
                          <Route
                            component={VerifyPhoneNumber}
                            path={Routes.Verify}
                          />
                        </Switch>
                      )}
                    </LocationProvider>
                  </Refresher>
                </BrowserRouter>
              </PersistGate>
            </Suspense>
          ) : (
            <NoInternetConnection />
          )}
        </Provider>
      </Div100vh>
    </Suspense>
  );
}

export default App;
