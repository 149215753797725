import { useCallback, useEffect } from 'react';

/**
 * Reload persisted page caused by browser state caching.
 *
 * Hook provides an easy way to handle this situation by automatically refreshing the page
 * when it's persisted in the browser's history and shown again.
 *
 * This improves the user experience by ensuring that the latest version of the website is always displayed to the user.
 * Even if they navigate away from it and then come back later.
 */
export const useRefreshOnPersistedPageShow = () => {
  const onPersistedPageShow = useCallback((event) => {
    if (event.persisted) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('pageshow', onPersistedPageShow);
    return () => window.removeEventListener('pageshow', onPersistedPageShow);
  }, [onPersistedPageShow]);
};
