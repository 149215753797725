import React from 'react';
import Cancel from '../../icons/assets/Cancel.webp';
import cn from 'classnames';
import styles from './CancelCall.module.css';
import { useTranslation } from 'react-i18next';

const Button = React.lazy(() => import('../../components/Button/Button'));
const Text = React.lazy(() => import('../../components/Text/Text'));

type CancelCallProps = {
  show: boolean;
  disabled: boolean;
  onClose: () => void;
  onCancel: () => void;
};

const CancelCall = ({ show, onClose, onCancel, disabled }: CancelCallProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn('content-container', styles.container, show && styles.show)}
    >
      <div>
        <img src={Cancel} alt="" />
      </div>
      <div className='margin-bottom-auto'>
        <Text size='big' header>
          {t('cancelCall.header')}
        </Text>
        <Text color='secondary'>{t('cancelCall.subheader')}</Text>
      </div>
      <div>
        <Button disabled={disabled} onClick={onClose}>
          {t('cancelCall.reject')}
        </Button>
        <Button
          disabled={disabled}
          size='big'
          type='outlined'
          color='primary'
          onClick={onCancel}
        >
          {t('cancelCall.confirm')}
        </Button>
      </div>
    </div>
  );
};

export default CancelCall;
