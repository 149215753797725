// @ts-nocheck

import { initialState } from '../store/appSlice';

export const migrations = {
  0: (state) => ({
    ...state,
    ...initialState,
  }),
};
