import React from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from '../../consts/routes';
import { useRefreshOnPersistedPageShow } from '../../hooks/useRefreshOnPersistedPageShow';

type RefresherProps = {
  children: React.ReactNode;
};

const Refresher = ({ children }: RefresherProps) => {
  const location = useLocation();

  const onVisibilityChange = React.useCallback(() => {
    if (
      document.visibilityState === 'visible' &&
      location.pathname !== Routes.Verify
    ) {
      window.location.reload();
    }
  }, [location]);

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [onVisibilityChange]);

  useRefreshOnPersistedPageShow();

  return <>{children}</>;
};

export default Refresher;
