/* eslint-disable @typescript-eslint/no-unused-vars */

import { AppState } from '../../store/store';
import React from 'react';
import { formatPhone } from '../../utils/phone-utils';
import styles from './ReportSummary.module.css';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Text = React.lazy(() => import('../../components/Text/Text'));

const ReportSummary = () => {
  const { t } = useTranslation();
  const { alarmId } = useParams<{ alarmId: string }>();

  const { ...state } = useSelector((state: AppState) => state);
  const {
    address,
    selectedEmergencyDetails,
    location,
    editableInfo,
    mentalHealthNote,
    additionalInfo,
    activeEmergencies
  } = useSelector(
    ({
      address,
      emergencyDetails: {
        selected: selectedEmergencyDetails,
        isNoneOptionSelected,
        additionalInfo,
      },
      mentalHealth: {
        note: mentalHealthNote
      },
      editableInfo,
      location,
      position,
      activeEmergencies,
    }: AppState) => ({
      address,
      selectedEmergencyDetails,
      location,
      position,
      isNoneOptionSelected,
      additionalInfo,
      mentalHealthNote,
      editableInfo,
      activeEmergencies
    })
  );

  const locationDetailsInfo = `${
    location.details.roomNumber
      ? `${t('common.room')}: ${location.details.roomNumber}`
      : ''
  }${
    location.details.floorNumber
      ? `${t('common.floor')}: ${location.details.floorNumber}`
      : ''
  }${
    location.details.exitNumber
      ? `${t('common.exit')}: ${location.details.exitNumber}`
      : ''
  }${
    location.details.busNumber
      ? `, ${t('common.busNumber')}: ${location.details.busNumber}`
      : ''
  }`;

  const weaponInvolved =
    state.weaponInvolved === 1
      ? ` ${t('summary.weaponInvolvedNo')}`
      : state.weaponInvolved === 2
      ? ` ${t('summary.weaponInvolvedNotSure')}`
        : ` ${t('summary.weaponInvolvedYes')}`;
  
  const emergencies = () => {
    const str = [];
    if (activeEmergencies.medical) {
      str.push(t('common.medical'))
    }
    if (activeEmergencies.mental) {
      str.push(t('common.mental'))
    }
    if (activeEmergencies.police) {
      str.push(t('common.police'))
    }
    if (activeEmergencies.fire) {
      str.push(t('common.fire'))
    }

    return str.join(',')
  }

  return (
    <>
      <div className={styles.container}>
        <Text weight='slight-bold'>{t('reportSummary.streetAddress')}:</Text>
        {address?.City && (
          <div>
            <div className={styles.section}>
              <Text size='small' weight='slight-bold' color='secondary'>{`${address.Address}`}</Text>
              <Text size='small' weight='slight-bold' color='secondary'>{`${address.City}, ${address.Postal}`}</Text>
              {locationDetailsInfo && (
                <Text size='small' weight='slight-bold' color='secondary'>
                  {locationDetailsInfo}
                </Text>
              )}
              
            </div>
            <hr></hr>
          </div>
        )}

        <Text weight='slight-bold'>{t('common.emergency')}:</Text>
        <div className={styles.section}>
          <Text size='small' weight='slight-bold' color='secondary'>
            {emergencies()}
          </Text>
        </div>
        <hr></hr>

        <div className={styles.weaponInvolved}>
          <Text weight='slight-bold'>{t('summary.weaponInvolved')}</Text>
          <Text weight='slight-bold' size='small' color='secondary'>
            {weaponInvolved}
          </Text>
        </div>
        
        {selectedEmergencyDetails.length > 0 && (
          <div>
            <hr></hr>
            <Text weight='slight-bold'>{t('summary.infoTitle1')}:</Text>
            <div className={styles.section}>
              <Text size='small' weight='slight-bold' color='secondary'>
                {selectedEmergencyDetails.map(
                    (name, index) =>
                      `${t(`emergenciesDetailsSection.${name.toLowerCase()}`)}${
                        state.emergencyDetails.selected.length - 1 === index
                          ? ''
                          : ','
                  } `)}
              </Text>
            </div>
          </div>
        )}
       
       {additionalInfo && (
        <div>
          <hr></hr>
          <Text className={styles.titleMargin} weight='slight-bold'>{t('summary.infoTitle2')}:</Text>
          <div className={styles.section}>
            <Text size='small' weight='slight-bold' color='secondary'>
              {mentalHealthNote[0] && (
                editableInfo ?
                  t('emergenciesDetailsSection.mentalhealthNote') + ' - ' :
                  t('emergenciesDetailsSection.mentalhealthNote')
              )}
              {editableInfo}
            </Text>
          </div>
        </div>
       )}
      </div>
    </>
  );
};

export default ReportSummary;
