import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentLocation } from '../hooks/useCurrentLocation';
import { Position } from '../shared/models/Position';
import { addLocation } from '../store/appSlice';
import { AppState } from '../store/store';
import { reverseGeocode } from '../utils/geocoder';

type LocationContextProps = {
  currentLocation: Position;
  isLoadingCurrentLocation: boolean;
  getCurrentLocation: () => void;
};

const LocationContext = React.createContext<LocationContextProps>({
  currentLocation: { lat: 0, lng: 0 },
  isLoadingCurrentLocation: false,
  getCurrentLocation: () => {},
});

export const LocationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const position = useSelector((state: AppState) => state.position);
  const {
    getCurrentLocation,
    isLoadingCurrentLocation,
    currentLocation,
  } = useCurrentLocation();

  React.useEffect(() => {
    if (
      !position.lat &&
      !position.lng &&
      currentLocation.lat &&
      currentLocation.lng
    ) {
      reverseGeocode(currentLocation)
        .then((address) => {
          dispatch(
            addLocation({
              position: {
                lat: currentLocation.lat,
                lng: currentLocation.lng,
                accuracy: Math.ceil(currentLocation.accuracy ?? 0),
              },
              address,
            })
          );
        })
        .catch(console.error);
    }
  }, [dispatch, currentLocation, position]);

  return (
    <LocationContext.Provider
      value={{ currentLocation, isLoadingCurrentLocation, getCurrentLocation }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => {
  const context = React.useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocation must be used within a LocationProvider');
  }
  return context;
};
