export enum Routes {
  Main = '/',
  LocationDetails = '/location-details',
  LocationFallback = '/location-fallback',
  Location = '/location',
  Setup = '/setup',
  Address = '/address',
  Summary = '/summary',
  Urgency = '/urgency',
  Emergency = '/emergency',
  Accommodations = '/accommodations',
  Weapons = '/weapons',
  Describe = '/describe',
  DescribeLocation = '/describe-location',
  AdditionalDetails = '/additional-details',
  Report = '/report/:alarmId',
  TestModeReport = '/test-mode-report',
  ReportEmergency = '/reportemergency',
  Verify = '/verify',
  Name = '/name',
}
