import axios from 'axios';
// import { body } from 'msw/lib/types/context';
import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

smoothscroll.polyfill();

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.interceptors.request.use(
  (request) => {
    if (!request.url?.startsWith('/auth')) {
      request.headers['Authorization'] = `Bearer ${window.localStorage.getItem(
        'token'
      )}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

window.addEventListener('load', function () {
  if (document.readyState === 'complete') {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
