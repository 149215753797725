import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../consts/routes';
import { Position } from '../shared/models/Position';
import { useDispatch } from 'react-redux';
import { setGeolocationIsEnabledStatus } from '../store/appSlice';

export const useCurrentLocation = () => {
  const history = useHistory();

  const [currentLocation, setCurrentLocation] = React.useState<Position>({
    lat: 0,
    lng: 0,
  });

  const [
    isLoadingCurrentLocation,
    setIsLoadingCurrentLocation,
  ] = React.useState(false);

  const dispatch = useDispatch();

  const getCurrentLocation = React.useCallback(() => {
    setIsLoadingCurrentLocation(true);
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setIsLoadingCurrentLocation(false);
        setCurrentLocation({
          lat: coords.latitude,
          lng: coords.longitude,
          accuracy: Math.ceil(coords.accuracy ?? 0),
        });
        dispatch(setGeolocationIsEnabledStatus(true));
      },
      () => {
        dispatch(setGeolocationIsEnabledStatus(false));
        history.push(Routes.LocationFallback);
      },
      {
        enableHighAccuracy: true,
      }
    );
  }, [dispatch, history]);

  return {
    currentLocation,
    isLoadingCurrentLocation,
    getCurrentLocation,
  };
};
