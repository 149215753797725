// @ts-nocheck
export const isMobileDevice = () => {
  if ('maxTouchPoints' in navigator) {
    return navigator.maxTouchPoints > 0 && window.innerWidth < 768;
  } else if ('msMaxTouchPoints' in navigator) {
    return navigator.msMaxTouchPoints > 0 && window.innerWidth < 768;
  } else {
    const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      return !!mQ.matches;
    } else if ('orientation' in window) {
      return true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      return /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad|iPod)\b/i.test(
        UA
      );
    }
  }
};
